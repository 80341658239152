import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/views/layout/index.vue'
/**
 * 私有路由表
 */
const privateRoutes: Array<RouteRecordRaw> = [
  

]

/**
 * 公开路由表
 */
 export const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/',
    redirect: '/platform',
  },
  {
    path: '/platform',
    component: Layout,
    redirect: '/platform/platforminfo',
    children: [
      {
        path: '/platform/platforminfo',
        name: 'app',
        component: () => import('@/views/platform/PlatformInfo.vue'),
        meta: {
          title: 'platform.platforminfo', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/feedback',
        name: 'feedback',
        component: () => import('@/views/platform/feedback.vue'),
        meta: {
          title: 'platform.feedback', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/orgrights',
        name: 'orgrights',
        component: () => import('@/views/platform/orgRights.vue'),
        meta: {
          title: 'platform.orgrights', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/myaccount',
        name: 'platform.myaccount',
        component: () => import('@/views/platform/deposits.vue'),
        meta: {
          title: 'myaccount', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/datainit',
        name: 'datainit',
        component: () => import('@/views/platform/initdata.vue'),
        meta: {
          title: 'datainit', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/templatedetails',
        name: 'templatedetails',
        component: () => import('@/views/platform/templateDetails.vue'),
        meta: {
          title: 'templatedetails', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/branchlist',
        name: 'branchlist',
        component: () => import('@/views/platform/branchlist.vue'),
        meta: {
          title: 'branchlist',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/fabriclist',
        name: 'fabriclist',
        component: () => import('@/views/platform/fabriclist.vue'),
        meta: {
          title: 'fabriclist',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/designers',
        name: 'designers',
        component: () => import('@/views/platform/designers.vue'),
        meta: {
          title: 'designers',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/manufacturers',
        name: 'manufacturers',
        component: () => import('@/views/platform/manufacturers.vue'),
        meta: {
          title: 'manufacturers',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/accounts',
        name: 'accounts',
        component: () => import('@/views/platform/accounts.vue'),
        meta: {
          title: 'platform.accounts',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/rights',
        name: 'rights',
        component: () => import('@/views/platform/roleFunctions.vue'),
        meta: {
          title: 'rights',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/orgdetails',
        name: 'orgdetails',
        component: () => import('@/views/platform/orgdetails.vue'),
        meta: {
          title: 'orgdetails',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/platform/icons',
        name: 'icons',
        component: () => import('@/views/layout/components/SvgDisplay.vue'),
        meta: {
          title: 'myappointment',
          icon: 'personnel-manage'
        }
      },
      
      
    ]    
  },
  {
    path:'/customers',
    component:Layout,
    redirect:'/customers/customers',
    children:[
      {
        path: '/customers/customers',
        name: 'customers',
        component: () => import('@/views/customers/customers.vue'),
        meta: {
          title: 'customers.customers',
          icon: 'personnel-manage',
          // keepAlive:true
        }
      },
      {
        path: '/customers/levels',
        name: 'levels',
        component: () => import('@/views/customers/levels.vue'),
        meta: {
          title: 'customers.levels',
          icon: 'personnel-manage',
          // keepAlive:true
        }
      },
      {
        path: '/customers/notes',
        name: 'notes',
        component: () => import('@/views/customers/notes.vue'),
        meta: {
          title: 'customers.notes',
          icon: 'personnel-manage',
          // keepAlive:true
        }
      },
      {
        path: '/customers/timeextention',
        name: 'timeextention',
        component: () => import('@/views/customers/timeExtention.vue'),
        meta: {
          title: 'customers.extention',
          icon: 'personnel-manage',
          // keepAlive:true
        }
      }
    ]
  },
  {
    path:'/products',
    component:Layout,
    redirect:'/products/bokes',
    children:[
      {
        path: '/products/bokes',
        name: 'bokes',
        component: () => import('@/views/products/products.vue'),
        meta: {
          title: 'products.bokes',
          icon: 'personnel-manage',
          // keepAlive:true
        }
      },
      {
        path: '/products/upshare',
        name: 'upshare',
        component: () => import('@/views/products/upShareToPlatform.vue'),
        meta: {
          title: 'products.upshare',
          icon: 'personnel-manage',
          // keepAlive:true
        }
      },
      {
        path: '/products/cloths',
        name: 'cloths',
        component: () => import('@/views/products/cloths.vue'),
        meta: {
          title: 'products.cloths',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/branchProducts',
        name: 'branchProducts',
        component: () => import('@/views/products/branchProducts.vue'),
        meta: {
          title: 'products.branchProducts',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/fabrics',
        name: 'fabrics',
        component: () => import('@/views/products/fabrics.vue'),
        meta: {
          title: 'products.fabrics',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/binding',
        name: 'binding',
        component: () => import('@/views/products/binding.vue'),
        meta: {
          title: 'products.binding',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/accessories',
        name: 'accessories',
        component: () => import('@/views/products/accessories.vue'),
        meta: {
          title: 'products.accessories',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/accessoriesDetails',
        name: 'accessoriesDetails',
        component: () => import('@/views/products/accessoriesDetails.vue'),
        meta: {
          title: 'products.accessoriesDetails',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/decoration',
        name: 'decoration',
        component: () => import('@/views/products/decorations.vue'),
        meta: {
          title: 'products.decoration',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/decorationDetails',
        name: 'decorationDetails',
        component: () => import('@/views/products/decorationDetails.vue'),
        meta: {
          title: 'products.decorationDetails',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/accessoryDetails',
        name: 'accessoryDetails',
        component: () => import('@/views/products/decorationDetails.vue'),
        meta: {
          title: 'products.accessoryDetails',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/fabricDetails',
        name: 'fabricDetails',
        component: () => import('@/views/products/fabricDetails.vue'),
        meta: {
          title: 'products.fabricDetails',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/clothDetails',
        name: 'clothDetails',
        component: () => import('@/views/products/clothDetail.vue'),
        meta: {
          title: 'products.clothDetails',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/products/productDetails',
        name: 'productDetails',
        component: () => import('@/views/products/productDetail.vue'),
        meta: {
          title: 'productDetails',
          icon: 'personnel-manage'
        }
      },
    ]
  },
  {
    path:'/audited', //审核管理
    component:Layout,
    redirect:'/audited/bokes',
    children:[
      {
        path: '/audited/bokes', //版型审核
        name: 'auditedbokes',
        component: () => import('@/views/audited/auditedBokes.vue'),
        meta: {
          title: 'audited.bokes',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/audited/decorations', //饰品审核
        name: 'auditeddecorations',
        component: () => import('@/views/audited/auditedDecorations.vue'),
        meta: {
          title: 'audited.decorations',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/audited/upShare', //平台售卖审核
        name: 'upShare',
        component: () => import('@/views/audited/auditedUpshare.vue'),
        meta: {
          title: 'audited.upShare',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/audited/fabrics', //面料审核
        name: 'auditedfabrics',
        component: () => import('@/views/audited/auditedFabrics.vue'),
        meta: {
          title: 'audited.fabrics',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/audited/settings', //审核设置
        name: 'auditedsettings',
        component: () => import('@/views/audited/auditedSettings.vue'),
        meta: {
          title: 'audited.settings',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/audited/ref', //引用审核
        name: 'auditedref',
        component: () => import('@/views/audited/auditedRef.vue'),
        meta: {
          title: 'audited.ref',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/audited/shared', //引用审核
        name: 'auditedshared',
        component: () => import('@/views/audited/auditedShared.vue'),
        meta: {
          title: 'audited.shared',
          icon: 'personnel-manage'
        }
      }
    ]
  },
  {
    path:'/orderprint',
    name:'orderprint',
    component: () => import('@/views/orders/printOrder.vue'),
        meta: {
          title: 'orders.orderprint',
          icon: 'personnel-manage'
        }
  },
  {
    path:'/orders', //订单管理
    component:Layout,
    redirect:'/orders/bokes',
    children:[
      {
        path: '/orders/bokes', //电子版订单
        name: 'bokesOrder',
        component: () => import('@/views/orders/bokes.vue'),
        meta: {
          title: 'orders.bokesOrder',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/cloths', //成衣现货订单
        name: 'clothsOrders',
        component: () => import('@/views/orders/cloths.vue'),
        meta: {
          title: 'orders.clothsOrder',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/upgration', //会员充值订单
        name: 'upgration',
        component: () => import('@/views/orders/upgration.vue'),
        meta: {
          title: 'orders.upgration',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/takeorders', //成衣接单
        name: 'takeorders',
        component: () => import('@/views/orders/takeOrders.vue'),
        meta: {
          title: 'orders.takeOrders',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/decoration', //饰品订单
        name: 'decorationOders',
        component: () => import('@/views/orders/decoration.vue'),
        meta: {
          title: 'orders.decorationOders',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/fabrics', //面料订单
        name: 'fabricsOrder',
        component: () => import('@/views/orders/fabrics.vue'),
        meta: {
          title: 'orders.fabricsOrder',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/physicalBokes', //纸质版订单
        name: 'physicalBokes',
        component: () => import('@/views/orders/physicalBokes.vue'),
        meta: {
          title: 'orders.physicalBokes',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/physicalPrinting', //纸质版打印
        name: 'physicalPrinting',
        component: () => import('@/views/orders/physicalPrinting.vue'),
        meta: {
          title: 'orders.physicalPrinting',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/tailored', //量体订单
        name: 'tailoredOrders',
        component: () => import('@/views/orders/tailored.vue'),
        meta: {
          title: 'orders.tailoredOrders',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/tailoredBokes', //定制版型订单
        name: 'tailoredBokes',
        component: () => import('@/views/orders/tailoredBokes.vue'),
        meta: {
          title: 'orders.tailoredBokes',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/tailoredCloths', //成衣定制订单
        name: 'tailoredCloths',
        component: () => import('@/views/orders/tailoredCloths.vue'),
        meta: {
          title: 'orders.tailoredCloths',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/tailoredMade',  //量体定制订单
        name: 'tailoredMade',
        component: () => import('@/views/orders/tailoredMade.vue'),
        meta: {
          title: 'orders.tailoredMade',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/orders/videoTeching', //课程订单
        name: 'videoTeching',
        component: () => import('@/views/orders/videoTeching.vue'),
        meta: {
          title: 'orders.videoTeching',
          icon: 'personnel-manage'
        }
      },
    ]
  },
  {
    path:'/system',
    component:Layout,
    redirect:'',
    children:[
      {
        path: '/system/clothSize',
        name: 'clothSize',
        component: () => import('@/views/system/clothSizeManagement.vue'),
        meta: {
          title: 'clothSize',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/banner',
        name: 'banner',
        component: () => import('@/views/system/bannerSettings.vue'),
        meta: {
          title: 'system.messages',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/messages',
        name: 'messages',
        component: () => import('@/views/system/messages.vue'),
        meta: {
          title: 'system.messages',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/clothCategory',
        name: 'clothCategory',
        component: () => import('@/views/system/clothCategory.vue'),
        meta: {
          title: 'system.clothCategory',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/clothStyle',
        name: 'clothStyle',
        component: () => import('@/views/system/clothStyle.vue'),
        meta: {
          title: 'system.clothStyle',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/productUnit',
        name: 'productUnit',
        component: () => import('@/views/system/productUnit.vue'),
        meta: {
          title: 'system.productUnit',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/clothSizeViewer',
        name: 'clothSizeViewer',
        component: () => import('@/views/system/clothSizeViewer.vue'),
        meta: {
          title: 'system.clothSizeViewer',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/clothSizeTemplate',
        name: 'clothSizeTemplate',
        component: () => import('@/views/system/clothSizeTemplate.vue'),
        meta: {
          title: 'system.clothSizeTemplate',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/fabricCategories',
        name: 'fabricCategories',
        component: () => import('@/views/system/fabricCategories.vue'),
        meta: {
          title: 'system.fabricCategories',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/videos',
        name: 'videos',
        component: () => import('@/views/system/videos.vue'),
        meta: {
          title: 'system.videos', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/settings',
        name: 'settings',
        component: () => import('@/views/system/settings.vue'),
        meta: {
          title: 'system.settings', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/operationLogs',
        name: 'operationLogs',
        component: () => import('@/views/system/operationLogs.vue'),
        meta: {
          title: 'system.operationLogs', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/system/course',
        name: 'course',
        component: () => import('@/views/system/course.vue'),
        meta: {
          title: 'system.course', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/icon',
        name: 'icon',
        component: () => import('@/views/layout/components/SvgDisplay.vue'),
        meta: {
          title: 'main.helpcenter', 
          icon: 'personnel-manage'
        }
      },
    ]
  },
  {
    path:'/inventory',
    component:Layout,
    redirect:'',
    children:[
      {
        path: '/inventory/storage',
        name: 'storage',
        component: () => import('@/views/inventory/storage.vue'),
        meta: {
          title: 'inventory.storage',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/inventory/instore',
        name: 'instore',
        component: () => import('@/views/inventory/instore.vue'),
        meta: {
          title: 'inventory.instore',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/inventory/outstore',
        name: 'outstore',
        component: () => import('@/views/inventory/outstore.vue'),
        meta: {
          title: 'inventory.outstore',
          icon: 'personnel-manage'
        }
      }
    ]
  },
  {
    path:'/financial',
    component:Layout,
    redirect:'',
    children:[
      {
        path: '/financial/recharge',
        name: 'recharge',
        component: () => import('@/views/financial/rechargeRecords.vue'),
        meta: {
          title: 'financial.recharge',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/financial/accountStatements',
        name: 'accountStatements',
        component: () => import('@/views/financial/accountStatements.vue'),
        meta: {
          title: 'financial.accountStatements',
          icon: 'personnel-manage'
        }
      },
      {
        path: '/financial/withdrawal',
        name: 'withdrawal',
        component: () => import('@/views/financial/withdraw.vue'),
        meta: {
          title: 'financial.withdrawal',
          icon: 'personnel-manage'
        }
      },
    ]
  }
]
  
  // {
  //   path: '/app',
  //   // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
  //   component: layout,
  //   redirect: '/app/myapp',
  //   children: [
  //     {
  //       path: '/app/myapp',
  //       name: 'profile',
  //       component: () => import('@/views/profile/index.vue'),
  //       meta: {
  //         title: 'profile',
  //         icon: 'el-icon-user'
  //       }
  //     }
  //   ]
  // }






const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...publicRoutes, ...privateRoutes]
})

export default router

