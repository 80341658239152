import request from '@/utils/request'
import store from '@/store'
export default {
  getOrders: (data) => {
    return request({
      url: 'order/searchOrders',
      method: 'POST',
      data
    })
  },
  getAfterSalesOrders: (data) => {
    return request({
      url: 'order/searchAfterSalesOrders',
      method: 'POST',
      data
    })
  },
  getClothAfterSalesOrders: (data) => {
    return request({
      url: 'order/clothAfterSalesOrders',
      method: 'POST',
      data
    })
  },
  getBodyImagesByOrderId: (id) => {
    return request({
      url: `order/bodyImagesByOrderId?orderId=${id}`,
      method: 'GET'
    })
  },
  getUndispatchClothOrders: (data) => {
    return request({
      url: 'order/undispatchClothOrders',
      method: 'POST',
      data
    })
  },  
  updateAfterSalesOrder:(data) => {
    return request({
      url: 'order/updateAfterSalesOrder',
      method: 'POST',
      data
    })
  },
  getCustomBokeOrders: (data) => {
    return request({
      url: 'order/searchCustomOrders',
      method: 'POST',
      data
    })
  },  
  getClothOrders: (data) => {
    return request({
      url: 'order/searchClothOrders',
      method: 'POST',
      data
    })
  },
  refound: (data) => {
    return request({
      url: 'native/refund',
      method: 'POST',
      data
    })
  },
  modifyOrderPrice: (data) => {
    return request({
      url: 'order/orderModifyRecord',
      method: 'POST',
      data
    })
  },
  addChatMessage: (data) => {
    return request({
      url: 'order/chatMessage',
      method: 'POST',
      data
    })
  },
  getChatMessage: (orderId,opid) => {
    return request({
      url: `order/chatMessage?orderId=${orderId}&operatorId=${opid}`,
      method: 'GET',
      
    })
  },
  orderStatus: (id) => {
    return request({
      url: `order/orderStatus?id=${id}`,
      method: 'GET',      
    })
  },
  getOrderStatus: (id) => {
    return request({
      url: `order/orderStatus?id=${id}`,
      method: 'GET',      
    })
  },
  getOdAttach: (id) => {
    return request({
      url: `order/odAttach?orderId=${id}`,
      method: 'GET',
    })
  },
  addOdAttach: (data) => {
    return request({
      url: 'order/odAttach',
      method: 'POST',
      data
    })
  },
  setOutOrder: (out,id) => {
    return request({
      url: `order/setOutOrder?outOrder=${out}&id=${id}`,
      method: 'GET',
    })
  },
  deleteOdAttach: (id) => {
    return request({
      url: `order/odAttach?id=${id}`,
      method: 'DELETE',
    })
  },
  getOrderOrgs: (data) => {
    return request({
      url: `order/orderOrgs`,
      method: 'POST',
      data
    })
  },
  getBokeOrderOrgs: (data) => {
    return request({
      url: `order/bokeOrderOrgs`,
      method: 'POST',
      data
    })
  },
  
  isAccepted: (id,orgId) => {
    return request({
      url: `order/accepted?id=${id}&orgId=${orgId}`,
      method: 'GET',
    })
  },
  getUnacceptClothOrders: (data: any) => {
    return request({
      url: 'order/unacceptClothOrders',
      method: 'POST',
      data
    })
  },
  getDispatchedClothOrders: (data: any) => {
    return request({
      url: 'order/dispatchedClothOrders',
      method: 'POST',
      data
    })
  },
  
  myMessages:()=>{
    return request({
      url: `order/messages`,
      method: 'GET',
    })
  },
  getCountOfmessages:()=>{
    return request({
      url: `order/countOfmessages`,
      method: 'GET',
    })
  },
  setMessageReaded:()=>{
    return request({
      url: `order/messageReaded`,
      method: 'GET',
    })
  },
  updateOrderStatus: (data: any) => {
    return request({
      url: 'order/editOrderStatus',
      method: 'POST',
      data
    })
  },
  patchUpdateOrderStatus: (data: any) => {
    return request({
      url: 'order/patchEditOrderStatus',
      method: 'POST',
      data
    })
  },
  
  getAccountHistories: (data: any) => {
    return request({
      url: 'client/accountHistories',
      method: 'POST',
      data
    })
  },
  saveAccount: (data: any) => {
    return request({
      url: 'client/saveAccount',
      method: 'POST',
      data
    })
  },
  getPaymentInfoByOrgId: (data) => {
    return request({
      url: `order/paymentInfoByOrgId`,
      method: 'POST',
      data      
    })
  },
  updateOrderDetailStatusByOrderId: ({orderId,status}) => {
    return request({
      url: `order/updateOrderDetailStatusByOrderId?orderId=${orderId}&status=${status}`,
      method: 'GET'
    })
  },
  saveOrgOrder: (data: any) => {
    return request({
      url: 'order/orgOrder',
      method: 'POST',
      data
    })
  },
  saveCourseInstallation: (data: any) => {
    return request({
      url: 'order/courseInstallation',
      method: 'POST',
      data
    })
  },
  searchCourseOrders: (data: any) => {
    return request({
      url: 'order/searchCourseOrders',
      method: 'POST',
      data
    })
  },
  saveAftersalesOrder: (data: any) => {
    return request({
      url: 'order/aftersalesOrder',
      method: 'POST',
      data
    })
  },
  getOrderModifyRecords: (id) => {
    return request({
      url: `order/orderModifyRecords?orderId=${id}`,
      method: 'GET'
    })
  },
  getOrderInfoById: (id) => {
    return request({
      url: `order/orderInfoById?id=${id}`,
      method: 'GET'
    })
  },
  
  getBokeOrderAttachFiles: ({productId,sizeName,orderId},callback) => {
    const myHeaders = new Headers();
      myHeaders.append("token", store.getters.token);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(process.env.VUE_APP_BASE_API+`/order/bokeOrderAttachs?orderId=${orderId}&productId=${productId}&sizeName=${sizeName}`, requestOptions as any)
        .then(response => {
          if (!response.ok) {
            throw new Error(`网络错误！状态码: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          if(callback){
            callback(blob)
          }          
        })
        .catch(error => console.error('Error:', error));
  },
  getExpressInfo:(data)=>{
    return request({
      url: `expressDelivery/queryTrack`,
      method: 'POST',
      data
    })
    
  },
  getPrintOrders:(data)=>{
    return request({
      url: `order/printOrders`,
      method: 'POST',
      data
    })
    
  },
  getOrder: (id) => {
    return request({
      url: `order/order/${id}`,
      method: 'GET',
    })
  },
  getRenewalOrders: (data) => {
    return request({
      url: `client/renewalsByCustomerId`,
      method: 'POST',
      data
    })
  },
  getAttachFiles: ({orderId,type},callback) => {
    const myHeaders = new Headers();
      myHeaders.append("token", store.getters.token);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(process.env.VUE_APP_BASE_API+`/order/odAttachFiles?orderId=${orderId}&type=${type}`, requestOptions as any)
        .then(response => {
          if (!response.ok) {
            throw new Error(`网络错误！状态码: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          if(callback){
            callback(blob)
          }          
        })
        .catch(error => console.error('Error:', error));
  },
}